<template>
    <div style="text-align: center; width:530px; margin: 0 auto;font-family: 'Noto Sans KR', '맑은 고딕', 'Malgun Gothic', 'dotum';
    font-weight:bold;font-size: 15px;margin-top:12%;letter-spacing: -1px;color:#555;height:567px;">

    </div>
</template>

<script>
  import ApiUtil from '@/api/api.util'
  import ApiConfig from '@/api/api.config'
  import EtnersCommonUtil from '@/common/etners.common.util'
  import EtnersKendoGridUtil from '@/common/kendo.grid.util'
  import EtnersParseUtil from '@/common/etners.parse.util'
  import EtnersDateTimeUtil from '@/common/etners.datetime.util'

  export default {
    name: 'CheckDevice',
    mounted() {
      const sessionCheckDevice = sessionStorage.getItem('checkDevice')
      if (sessionCheckDevice != null) {
        this.$router.push({name:'UserDashboard'})
      }else {
        this.redirectPageByDevice()
      }
    },
    methods: {
      redirectPageByDevice: function() {
        let pcDevice = "win16|win32|win64|mac|macintel"
        let currentDevice = navigator.platform
        let mobileTestFl = false
        let targetUrl

        sessionStorage.setItem("checkDevice", "PC")
        ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/findAllMenuForWmGbn.json`, { wmGbn : '01' }).then(function (response) {
          if (response.data === undefined || response.data.resultStatus === undefined) {
              kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
              return false
            }

            const resultStatus = response.data.resultStatus

            if (resultStatus.messageCode !== '2000') {
              kendo.alert(resultStatus.messageText)
              return false
            }

            //웹 사용자 메뉴 세팅
            sessionStorage.setItem('webMenuData', JSON.stringify(response.data.resultData))
          })
          this.$router.push({name:'UserDashboard'})
      }
    }

  }
</script>

<style scoped>

</style>
